<template>
  <div>
    <b-card title="Créer un ticket de solution - SAV">
      <b-row class="match-height">
        <b-col
          md="6"
          lg="6"
          class=""
        >
          Type du ticket:
          <v-select
            v-model="type"
            :dir="'ltr'"
            label="Type"
            placeholder="Type"
            :options="types"
          />
        </b-col>
        <b-col
          md="6"
          lg="6"
          class=""
        >
          Sous-type:
          <b-form-input
            id="subType"
            v-model="subType"
            trim
            placeholder="Sous-type"
          />
        </b-col>
        <b-col
          md="12"
          lg="12"
          class="mt-2"
        >
          Titre:
          <b-form-input
            id="subType"
            v-model="title"
            trim
            placeholder="Titre"
          />
        </b-col>
        <b-col
          md="12"
          lg="12"
          class="mt-2"
        >
          Description:
          <b-form-textarea
            v-model="description"
            class="mr-4"
            style="width: 100%"
          />
        </b-col>
        <b-col
          md="12"
          lg="12"
          class="mt-2"
        >
          Schema de résolution:
          <Ticket
            @change="value => schema = value"
            @save="create"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  // BCardBody,
  BRow,
  BCol,
  // BImg,
  // BButton,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ticket from '@/components/orders/Ticket.vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    vSelect,
    BCard,
    BRow,
    BCol,
    BFormTextarea,
    Ticket,
    // BImg,
    // BButton,
    // BCardBody,
    BFormInput,
    // BInputGroup,
  },
  data() {
    return {
      baseProducts: [],
      products: [],
      product: {},
      showModal: false,
      options: { mode: 'code' },
      subType: null,
      schema: null,
      title: '',
      types: ['Problème de livraison', 'Sur Mesure', 'Annulation / Modification commande', 'Article défectueux', 'Retours', 'Remboursement'],
      categoryInformations: [],
    }
  },
  methods: {
    async create(value) {
      try {
        const mappedSolution = {
          type: this.type,
          subType: this.subType,
          title: this.title,
          schemaData: value.diagram,
        }
        await this.$http.post('/admin/solution', { ...mappedSolution })
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Bien sauvegardé.',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } catch (err) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Erreur.',
            icon: 'EditIcon',
            variant: 'error',
          },
        })
        console.log(err)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
