<template>
  <div>
    <div id="diagram-contanier">
      <div id="diagram-tool">
        <slot>
          <b-button
            class="cursor-pointer mr-2"
            type="primary"
            @click="save"
          >
            Sauvegarder
          </b-button>
          <b-button
            class="cursor-pointer mr-2"
            @click="load"
          >
            Charger
          </b-button>
          <b-button
            class="cursor-pointer"
            @click="makeSvg"
          >
            Télécharger
          </b-button>
        </slot>
      </div>
      <div id="diagram-wrap">
        <div
          id="diagram-palette"
          ref="palette"
        />
        <div
          id="diagram"
          ref="diagram"
        />
      </div>
    </div>
    <b-row class="match-height">
      <b-col
        md="3"
        lg="3"
        class="mt-2"
      >
        <b-button
          variant="outline-primary"
          @click="update"
        >Sauvegarder</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import go from 'gojs'
import {
  BButton,
} from 'bootstrap-vue'

const $ = go.GraphObject.make
export default {
  name: 'Index',
  components: { BButton },
  data() {
    return {
      diagram: null,
      palette: null,
      textStyle: {
        font: 'bold 9pt Lato, Helvetica, Arial, sans-serif',
        stroke: '#F8F8F8',
      },
      chart: {
        class: 'GraphLinksModel',
        linkFromPortIdProperty: 'fromPort',
        linkToPortIdProperty: 'toPort',
      },
    }
  },
  mounted() {
    const showLinkLabel = e => {
      const label = e.subject.findObject('LABEL')
      if (label !== null) { label.visible = e.subject.fromNode.data.category === 'Conditional' }
    }

    this.diagram = $(go.Diagram, this.$refs.diagram, {
      LinkDrawn: showLinkLabel, // this DiagramEvent listener is defined below
      LinkRelinked: showLinkLabel,
      'undoManager.isEnabled': true, // enable undo & redo
    })
    this.setLinkTemplate()
    this.createPattle()

    // 设置 linkFromPortIdProperty 属性, 避免 link 自己重绘
    this.diagram.model.linkFromPortIdProperty = 'fromPort' // 必须记住portIds
    this.diagram.model.linkToPortIdProperty = 'toPort'

    this.diagram.toolManager.linkingTool.temporaryLink.routing = go.Link.Orthogonal
    this.diagram.toolManager.relinkingTool.temporaryLink.routing = go.Link.Orthogonal
  },
  methods: {
    update() {
      const blob = this.createSvg()
      this.$emit('save', { diagram: this.diagram.model.toJson(), blob })
    },
    save() {
      console.log(this.diagram.model.toJson())
      this.$emit('change', this.diagram.model.toJson())
    },
    load() {
      const chart = {
        class: 'GraphLinksModel',
        linkFromPortIdProperty: 'fromPort',
        linkToPortIdProperty: 'toPort',
        nodeDataArray: [
          { text: 'D\u00e9part', key: -2, loc: '-76.01171875 -97.8984375' },
          {
            category: 'Conditional', text: 'Condition', key: -3, loc: '-63.0390625 6.1875',
          },
          { text: 'Etape', key: -4, loc: '-281.93359375 160.45703125' },
          { text: 'Etape', key: -5, loc: '125.16796875 150.1484375' },
        ],
        linkDataArray: [
          {
            from: -3, to: -4, fromPort: 'L', toPort: 'T', visible: true, points: [-123.0390625, 6.1875, -133.0390625, 6.1875, -281.93359375, 6.1875, -281.93359375, 68.322265625, -281.93359375, 130.45703125, -281.93359375, 140.45703125], text: "Si il dit qu'il ne veut pas\n",
          },
          {
            from: -3, to: -5, fromPort: 'R', toPort: 'T', visible: true, points: [-3.0390625, 6.1875, 6.9609375, 6.1875, 125.16796875, 6.1875, 125.16796875, 63.16796875, 125.16796875, 120.1484375, 125.16796875, 130.1484375], text: 'Si il veut\n',
          },
          {
            from: -2, to: -3, fromPort: 'B', toPort: 'T', points: [-76.01171875, -77.8984375, -76.01171875, -67.8984375, -76.01171875, -45.85546875, -63.0390625, -45.85546875, -63.0390625, -23.8125, -63.0390625, -13.8125],
          },
        ],
      }
      this.diagram.model = go.Model.fromJson(chart)
    },
    createPattle() {
      this.diagram.nodeTemplateMap.add(
        'Comment', // the default category
        $(
          go.Node,
          'Table',
          this.nodeStyle(),
          // the main object is a Panel that surrounds a TextBlock with a rectangular Shape
          $(
            go.Panel,
            'Auto',
            $(
              go.TextBlock,
              {
                font: 'bold 9pt Lato, Helvetica, Arial, sans-serif',
              },
              {
                margin: 24,
                editable: true,
              },
              new go.Binding('text').makeTwoWay(),
            ),
          ),
          // four named ports, one on each side:
          this.makePort('T', go.Spot.Top, go.Spot.Top, false, true),
          this.makePort('L', go.Spot.Left, go.Spot.Left, true, true),
          this.makePort('R', go.Spot.Right, go.Spot.Right, true, true),
          this.makePort('B', go.Spot.Bottom, go.Spot.Bottom, true, false),
        ),
      )
      this.diagram.nodeTemplateMap.add(
        '', // the default category
        $(
          go.Node,
          'Table',
          this.nodeStyle(),
          // the main object is a Panel that surrounds a TextBlock with a rectangular Shape
          $(
            go.Panel,
            'Auto',
            $(
              go.Shape,
              'RoundedRectangle',
              {
                fill: '#EA2027',
              },
              new go.Binding('figure', 'figure'),
              new go.Binding('fill', 'color'),
            ),
            $(
              go.TextBlock,
              {
                font: 'bold 9pt Lato, Helvetica, Arial, sans-serif',
                stroke: '#F8F8F8',
              },
              {
                margin: 24,
                editable: true,
              },
              new go.Binding('fill', 'color'),
              new go.Binding('text').makeTwoWay(),
            ),
            {
              // define a context menu for each node
              contextMenu: $(
                'ContextMenu', // that has one button
                $(
                  'ContextMenuButton',
                  {
                    'ButtonBorder.fill': 'white',
                    _buttonFillOver: 'skyblue',
                  },
                  $(go.TextBlock, 'Valider'),
                  { click: this.validate },
                ),
                $(
                  'ContextMenuButton',
                  {
                    'ButtonBorder.fill': 'white',
                    _buttonFillOver: 'skyblue',
                  },
                  $(go.TextBlock, 'Annuler'),
                  { click: this.cancelled },
                ),
                // more ContextMenuButtons would go here
              ), // end Adornment
            },
          ),
          // four named ports, one on each side:
          this.makePort('T', go.Spot.Top, go.Spot.Top, false, true),
          this.makePort('L', go.Spot.Left, go.Spot.Left, true, true),
          this.makePort('R', go.Spot.Right, go.Spot.Right, true, true),
          this.makePort('B', go.Spot.Bottom, go.Spot.Bottom, true, false),
        ),
      )
      this.diagram.nodeTemplateMap.add(
        'Conditional',
        $(
          go.Node,
          'Table',
          this.nodeStyle(),
          // the main object is a Panel that surrounds a TextBlock with a rectangular Shape
          $(
            go.Panel,
            'Auto',
            $(
              go.Shape,
              'Diamond',
              {
                desiredSize: new go.Size(120, 40),
                fill: '#F79F1F',
                strokeWidth: 0,
              },
              new go.Binding('figure', 'figure'),
            ),
            $(
              go.TextBlock,
              this.textStyle,
              {
                margin: 8,
                maxSize: new go.Size(160, NaN),
                wrap: go.TextBlock.WrapFit,
                editable: true,
              },
              new go.Binding('text').makeTwoWay(),
            ),
          ),
          // four named ports, one on each side:
          this.makePort('T', go.Spot.Top, go.Spot.Top, false, true),
          this.makePort('L', go.Spot.Left, go.Spot.Left, true, true),
          this.makePort('R', go.Spot.Right, go.Spot.Right, true, true),
          this.makePort('B', go.Spot.Bottom, go.Spot.Bottom, true, false),
        ),
      )
      this.diagram.nodeTemplateMap.add(
        'Start',
        $(
          go.Node,
          'Table',
          this.nodeStyle(),
          $(
            go.Panel,
            'Spot',
            $(go.Shape, 'Ellipse', {
              desiredSize: new go.Size(120, 40),
              fill: '#009432',
              strokeWidth: 0,
            }),
            $(go.TextBlock, 'Start', this.textStyle, new go.Binding('text')),
          ),
          // 创建端口, 以供连线(开始节点仅创建底部一个端口)
          // this.makePort('L', go.Spot.Left, go.Spot.Left, true, false),
          // this.makePort('R', go.Spot.Right, go.Spot.Right, true, false),
          this.makePort('B', go.Spot.Bottom, go.Spot.Bottom, true, false),
        ),
      )
      this.diagram.nodeTemplateMap.add(
        'End',
        $(
          go.Node,
          'Table',
          this.nodeStyle(),
          $(
            go.Panel,
            'Spot',
            $(go.Shape, 'Ellipse', {
              width: 100,
              stretch: go.GraphObject.Fill,
              fill: '#006266',
              strokeWidth: 0,
            }),
            $(go.TextBlock, 'End', this.textStyle, new go.Binding('text')),
          ),
          // 创建端口, 以供连线(结束节点仅创建顶部一个端口)
          this.makePort('T', go.Spot.Top, go.Spot.Top, false, true),
          // this.makePort('L', go.Spot.Left, go.Spot.Left, false, true),
          // this.makePort('R', go.Spot.Right, go.Spot.Right, false, true)
        ),
      )

      this.diagram.addModelChangedListener(evt => {
        if (evt.isTransactionFinished) {
          this.save(evt.model)
        }
      })

      this.palette = $(
        go.Palette,
        this.$refs.palette, // must name or refer to the DIV HTML element
        {
          // Instead of the default animation, use a custom fade-down
          allowZoom: false,
          'animationManager.initialAnimationStyle': go.AnimationManager.None,
          InitialAnimationStarting: this.animateFadeDown, // Instead, animate with this function
          nodeTemplateMap: this.diagram.nodeTemplateMap, // share the templates used by myDiagram
          model: new go.GraphLinksModel([
            // specify the contents of the Palette
            { category: 'Start', text: 'Départ' },
            { text: 'Etape' },
            { category: 'Conditional', text: 'Condition' },
            { category: 'End', text: 'Fin' },
            { category: 'Comment', text: 'Commentaire' },
          ]),
        },
      )
    },
    validate(e, obj) {
      this.diagram.commit(d => {
        // get the context menu that holds the button that was clicked
        const contextmenu = obj.part
        // get the node data to which the Node is data bound
        const nodedata = contextmenu.data
        // compute the next color for the node
        const newcolor = 'green'
        // eslint-disable-next-line default-case
        // modify the node data
        // this evaluates data Bindings and records changes in the UndoManager
        d.model.set(nodedata, 'color', newcolor)
      }, 'changed color')
    },
    cancelled(e, obj) {
      this.diagram.commit(d => {
        // get the context menu that holds the button that was clicked
        const contextmenu = obj.part
        // get the node data to which the Node is data bound
        const nodedata = contextmenu.data
        // compute the next color for the node
        const newcolor = 'red'
        // eslint-disable-next-line default-case
        // modify the node data
        // this evaluates data Bindings and records changes in the UndoManager
        d.model.set(nodedata, 'color', newcolor)
      }, 'changed color')
    },
    changeColor(e, obj) {
      this.diagram.commit(d => {
        // get the context menu that holds the button that was clicked
        const contextmenu = obj.part
        // get the node data to which the Node is data bound
        const nodedata = contextmenu.data
        // compute the next color for the node
        let newcolor = 'lightblue'
        // eslint-disable-next-line default-case
        switch (nodedata.color) {
          case 'lightblue':
            newcolor = 'lightgreen'
            break
          case 'lightgreen':
            newcolor = 'lightyellow'
            break
          case 'lightyellow':
            newcolor = 'orange'
            break
          case 'orange':
            newcolor = 'lightblue'
            break
        }
        // modify the node data
        // this evaluates data Bindings and records changes in the UndoManager
        d.model.set(nodedata, 'color', newcolor)
      }, 'changed color')
    },
    myCallback(blob) {
      const url = window.URL.createObjectURL(blob)
      const filename = 'schema-solution.svg'

      const a = document.createElement('a')
      a.style = 'display: none'
      a.href = url
      a.download = filename

      // IE 11
      if (window.navigator.msSaveBlob !== undefined) {
        window.navigator.msSaveBlob(blob, filename)
        return
      }

      document.body.appendChild(a)
      requestAnimationFrame(() => {
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      })
    },
    createSvg() {
      const svg = this.diagram.makeSvg({ scale: 1, background: 'white' })
      const svgstr = new XMLSerializer().serializeToString(svg)
      const blob = new Blob([svgstr], { type: 'image/svg+xml' })
      return blob
    },
    makeSvg() {
      const svg = this.diagram.makeSvg({ scale: 1, background: 'white' })
      const svgstr = new XMLSerializer().serializeToString(svg)
      const blob = new Blob([svgstr], { type: 'image/svg+xml' })
      this.myCallback(blob)
    },
    // 设置 全局 link 样式
    setLinkTemplate() {
      this.diagram.linkTemplate = $(
        go.Link, // the whole link panel
        {
          routing: go.Link.AvoidsNodes, // 避免 link 穿过节点
          curve: go.Link.JumpOver, //  交叉线 设置
          corner: 5, // 连接线转角弧度
          toShortLength: 4,
          relinkableFrom: true,
          relinkableTo: true,
          reshapable: true,
          // resegmentable: true,
          // mouse-overs subtly highlight links:
          mouseEnter(e, link) {
            // eslint-disable-next-line no-param-reassign
            link.findObject('HIGHLIGHT').stroke = 'rgba(30,144,255,0.2)'
          },
          mouseLeave(e, link) {
            // eslint-disable-next-line no-param-reassign
            link.findObject('HIGHLIGHT').stroke = 'transparent'
          },
          selectionAdorned: false,
        },
        new go.Binding('points').makeTwoWay(),
        $(
          go.Shape, // the highlight shape, normally transparent
          {
            isPanelMain: true,
            strokeWidth: 8,
            stroke: 'transparent',
            name: 'HIGHLIGHT',
          },
        ),
        $(
          go.Shape, // the link path shape
          { isPanelMain: true, stroke: 'gray', strokeWidth: 2 },
          new go.Binding('stroke', 'isSelected', (sel => (sel ? 'dodgerblue' : 'gray'))).ofObject(),
        ),
        $(
          go.Shape, // the arrowhead
          { toArrow: 'standard', strokeWidth: 0, fill: 'gray' },
        ),
        $(
          go.Panel,
          'Auto', // the link label, normally not visible
          {
            visible: false,
            name: 'LABEL',
            segmentIndex: 2,
            segmentFraction: 0.5,
          },
          new go.Binding('visible', 'visible').makeTwoWay(),
          $(
            go.Shape,
            'RoundedRectangle', // the label shape
            { fill: '#F8F8F8', strokeWidth: 0 },
          ),
          $(
            go.TextBlock,
            'Explication', // the label
            {
              textAlign: 'center',
              font: '8pt helvetica, arial, sans-serif',
              stroke: '#333333',
              editable: true,
            },
            new go.Binding('text').makeTwoWay(),
          ),
        ),
      )
    },
    nodeStyle() {
      return [
        new go.Binding('location', 'loc', go.Point.parse).makeTwoWay(
          go.Point.stringify,
        ),
        { resizable: true, resizeObjectName: 'R', locationSpot: go.Spot.Center },
      ]
    },
    // 创建端口, 以供连线
    makePort(name, align, spot, output, input) {
      const horizontal = align.equals(go.Spot.Top) || align.equals(go.Spot.Bottom)
      // the port is basically just a transparent rectangle that stretches along the side of the node,
      // and becomes colored when the mouse passes over it
      return $(go.Shape, {
        fill: 'transparent', // changed to a color in the mouseEnter event handler
        strokeWidth: 0, // no stroke
        width: horizontal ? NaN : 8, // if not stretching horizontally, just 8 wide
        height: !horizontal ? NaN : 8, // if not stretching vertically, just 8 tall
        alignment: align, // align the port on the main Shape
        stretch: horizontal
          ? go.GraphObject.Horizontal
          : go.GraphObject.Vertical,
        portId: name, // declare this object to be a "port"
        fromSpot: spot, // declare where links may connect at this port
        fromLinkable: output, // declare whether the user may draw links from here
        toSpot: spot, // declare where links may connect at this port
        toLinkable: input, // declare whether the user may draw links to here
        cursor: 'pointer', // show a different cursor to indicate potential link point
        mouseEnter(e, port) {
          // the PORT argument will be this Shape
          // eslint-disable-next-line no-param-reassign
          if (!e.diagram.isReadOnly) port.fill = 'rgba(255,0,255,0.5)'
        },
        mouseLeave(e, port) {
          // eslint-disable-next-line no-param-reassign
          port.fill = 'transparent'
        },
      })
    },
    animateFadeDown(e) {
      const { diagram } = e
      const animation = new go.Animation()
      animation.isViewportUnconstrained = true // So Diagram positioning rules let the animation start off-screen
      animation.easing = go.Animation.EaseOutExpo
      animation.duration = 900
      // Fade "down", in other words, fade in from above
      animation.add(
        diagram,
        'position',
        diagram.position.copy().offset(0, 200),
        diagram.position,
      )
      animation.add(diagram, 'opacity', 0, 1)
      animation.start()
    },
  },
}
</script>

<style lang="scss" scoped>
#diagram-contanier {
  height: 500px;
  display: flex;
  flex-direction: column;
  border: 1px solid #eee;
  #diagram-tool {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: #dfdfdf;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
  }

  #diagram-wrap {
    flex-grow: 1;
    height: 100%;
    display: flex;
    #diagram-palette {
      flex: 0 0 140px;
      height: 100%;
      // border-right: 1px solid #dfdfdf;
      background-color: #eee;
    }
    #diagram {
      width: 100%;
      flex-grow: 1;
    }

    ::v-deep canvas {
      outline: none;
    }
  }
}
</style>
